@font-face {
	font-family: FuturaMediumC;
	src: url("./Assets/FuturaMediumC.eot");
	src: url("./Assets/FuturaMediumC.woff") format("woff");
}

body {
	margin: 0;
	font-family: FuturaMediumC, -apple-system, BlinkMacSystemFont, "Segoe UI",
		"Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body * {
	font-family: "FuturaMediumC" !important;
}

code {
	font-family: FuturaMediumC, source-code-pro, Menlo, Monaco, Consolas,
		"Courier New", monospace;
}
